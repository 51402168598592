import * as Yup from "yup";
import LayoutAuth from "../../layouts/LayoutAuth";
import { ErrorMessage, Field, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import toast from "react-hot-toast";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";

function ProfileEditPage() {
  const navigate = useNavigate();
  const [currentUser] = useContext(UserContext);
  const [metamaskAddress, setMetamaskAddress] = useState();
  const [solanaAddress, setSolanaAddress] = useState();
  const { internalAPIClient, internalAPIClientAbortController } = useAuthenticatedHttpClient();

	useEffect(() => {
    setMetamaskAddress(currentUser?.bsc_wallet);
    setSolanaAddress(currentUser?.solana_wallet);
  }, [currentUser]);

  useEffect(() => {
    const getMetamaskAddress = localStorage.getItem("metask_address");
    if (getMetamaskAddress) {
      setMetamaskAddress(getMetamaskAddress);
    } else {
      return navigate("/presale");
    }
  }, [navigate]);
	
	const handleConnectPhantom = async () => {
    try {
      const { solana } = window;
      if (solana) {
        if (solana.isPhantom) {
          const response = await solana.connect({ onlyIfTrusted: false });
          setSolanaAddress(response.publicKey.toString());
        } else {
          toast.error("Please install phantom wallet");
        }
      } else {
        toast.error("Please install phantom wallet");
      }
    } catch (error) {
      toast.error("Error occured!");
      console.log(error);
    }
  };

  const handleOnSubmit = async (values) => {
		if (solanaAddress === null || solanaAddress === undefined) {
			return toast.error("SOL address is required");
    }

    // Update/edit profile api call
    let userData;
    try{
      userData = await internalAPIClient.put(`/user/${metamaskAddress}`, {
        name: values.name,
        email: values.email,
        solana_wallet: solanaAddress,
      });
      localStorage.setItem("x-user-kitsu", JSON.stringify(userData?.data?.data));
    }catch(e){
      console.log(e);
      toast.error("Failed to update profile");
      setTimeout(() => {}, 3000);
      return;
    }

    toast.success("Success!");
    setTimeout(() => {
      window.location.href ="/presale";
    }, 500);
  };

  return (
    <LayoutAuth title="Complete Your Registration">
      <Formik
        enableReinitialize={true}
        initialValues={{ name: currentUser?.name, email: currentUser?.email }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          email: Yup.string().email("Invalid email address").required("Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleOnSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="w-full space-y-1">
              <label htmlFor="name">Name</label>
              <Field type="text" name="name" className="w-full rounded-md" />
              <ErrorMessage name="name" component="div" className="text-red-700" />
            </div>
            <div className="w-full space-y-1">
              <label htmlFor="email">Email Address</label>
              <Field type="email" name="email" className="w-full rounded-md" />
              <ErrorMessage name="email" component="div" className="text-red-700" />
            </div>
            <div className="w-full space-y-1">
              <div className="flex justify-between">
                <label htmlFor="password">SOL Address</label>
                <button type="button" className="cursor-pointer underline font-bold hover:text-brown" onClick={() => handleConnectPhantom()}>
                  + Connect Wallet
                </button>
              </div>
              <input type="text" className="w-full rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed" disabled value={solanaAddress} />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="relative inline-block font-barlow font-medium uppercase text-2xl bg-brown text-white w-full p-3 rounded-xl border-x-4 border-l-neonblue border-r-neonpink group transition-all hover:contrast-125 hover:border-x-primary hover:-translate-y-1 hover:shadow-xl hover:shadow-neonpink/50"
            >
              Save Profile
            </button>
          </form>
        )}
      </Formik>
      <div className="text-center mt-6">
        <Link to="/presale">
          <p className="font-bold text-brown underline hover:no-underline">Back to Presale</p>
        </Link>
      </div>
    </LayoutAuth>
  );
}

export default ProfileEditPage;
